import { curry } from 'utils'

/**
 * Appends children to a parent
 * @param {Node} parentNode
 * @param {Node} children
 * @return {*}
 */
export default curry((parentNode, children) => {
  if (Array.isArray(children)) {
    return parentNode.append(...children)
  }

  parentNode.append(children)
})
