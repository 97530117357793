import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'datePicker']

  connect () {
    this.updateEstimatedShippingDate()
  }

  openDatePicker () {
    this.datePickerTarget.showPicker()
  }

  updateEstimatedShippingDate () {
    this.datePickerTarget.addEventListener('change', () => {
      this.submitForm()
    })
  }

  deliveryTypeChanged (_event) {
    this.submitForm()
  }

  submitForm () {
    Rails.fire(this.formTarget, 'submit')
  }
}
