import { isNil, throwError } from 'utils'

/**
 * Helper class that helps dealing with min/max adjustments,
 * to make users unable to specify max value less than specified minimum and such.
 */
export default class {
  constructor () {
    this.state = {}
    this.range = {}
  }

  fieldLimit (prop, value) {
    const func = this[`${prop}FieldLimit`]
    if (!func) { return throwError(`unknown field limit for "${prop}"`) }
    return func.call(this, value)
  }

  maxFieldLimit (value) {
    const min = this.state.min || this.range.min
    const max = this.range.max

    return this.within(min, max, value)
  }

  minFieldLimit (value) {
    const min = this.range.min
    const max = this.state.max || this.range.max

    return this.within(min, max, value)
  }

  within (min, max, value) {
    if (isNil(min)) { min = -Infinity }
    if (isNil(max)) { max = +Infinity }

    if (value < min) { return min }
    if (value > max) { return max }

    return value
  }
}
