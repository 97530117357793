import { Controller } from 'stimulus'
import { head, last, map, reduceIndexed, props, throttle, addEventListener } from 'utils'

export default class extends Controller {
  static targets = ['container', 'item', 'controls']

  connect () {
    this.maybeHideControls()
    this.maybeHideControls = this.maybeHideControls.bind(this)

    this.removeResizeEvent = addEventListener(window, 'resize', throttle(this.maybeHideControls, 100), true)
  }

  disconnect () {
    this.removeResizeEvent()
  }

  maybeHideControls () {
    if (!this.hasControlsTarget || !this.hasContainerTarget) { return }

    this.controlsTarget.style.display = this.nothingToScroll ? 'none' : ''
  }

  get nothingToScroll () {
    return this.containerTarget.scrollWidth <= this.containerTarget.clientWidth
  }

  scrollLeft () {
    const prevItem = this.itemTargets[head(this.visibleIndices) - 1]
    this.scrollToItem(prevItem)
  }

  scrollRight () {
    const nextItem = this.itemTargets[last(this.visibleIndices) + 1]
    this.scrollToItem(nextItem)
  }

  get visibleIndices () {
    const [containerLeftBoundary, containerRightBoundary] = this.elementHorizontalPosition(this.containerTarget)

    const takeVisible = (acc, [leftItemBoundary, rightItemBoundary], index) => {
      if (containerLeftBoundary < leftItemBoundary && containerRightBoundary > rightItemBoundary) {
        acc.push(index)
      }

      return acc
    }

    return reduceIndexed(takeVisible, [], this.itemsBoundaries)
  }

  get itemsBoundaries () {
    return map((item) => {
      const [itemLeftBoundary, itemRightBoundary] = this.elementHorizontalPosition(item)
      const [leftPadding, rightPadding] = this.elementHorizontalPaddings(item)

      // compute boundaries excluding paddings, so they are narrower than it seems for better calculations
      return [
        itemLeftBoundary + leftPadding,
        itemRightBoundary - rightPadding
      ]
    }, this.itemTargets)
  }

  elementHorizontalPosition (el) {
    return props(['left', 'right'], el.getBoundingClientRect())
  }

  elementHorizontalPaddings (el) {
    const styles = window.getComputedStyle(el)
    return [parseFloat(styles.paddingLeft), parseFloat(styles.paddingRight)]
  }

  scrollToItem (item) {
    if (!item) { return }
    item.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
  }
}
