import { el, setAttr } from 'utils/dom'
import { pick, prop, isNil, addEventListener } from 'utils'

export default class {
  static attributes = ['placeholder', 'min', 'max']

  constructor (prop, limiter, attributes) {
    this.state = {}
    this.limiter = limiter
    this.prop = prop
    this.el = el('input.form-control.hide-arrows-controls', attributes)
    addEventListener(this.el, 'blur', this.handleBlur.bind(this))
  }

  get value () {
    return this.el.value
  }

  set value (value) {
    this.el.value = isNil(value) ? '' : String(value)
  }

  handleBlur () {
    if (!this.handleChange) { return }

    if (this.value) {
      this.value = this.limiter.fieldLimit(this.prop, Number(this.value))
    }

    this.handleChange(this.prop, this.value)
  }

  update (state, onChange, attributes) {
    this.state = state
    this.handleChange = onChange

    this.value = prop(this.prop, state)
    setAttr(this.el, pick(this.constructor.attributes, attributes))
  }
}
