import { assocPath, has, reduce } from 'utils'

export default function connectCustomRefinementList (renderFn, unmountFn = () => {}) {
  return function customRefinementList (widgetParams) {
    const {
      attribute,
      sortBy,
      limit = Infinity
    } = widgetParams
    const connectorState = { limit, canLimit: limit !== Infinity, showAll: limit === Infinity }

    let toggleShowMore = () => {}
    function createToggleShowMore (renderOptions, widget) {
      return () => {
        connectorState.showAll = !connectorState.showAll
        widget.render(renderOptions)
      }
    }
    function cachedToggleShowMore () {
      return toggleShowMore
    }

    return {
      $$type: 'cocoli.refinementList',
      init (initOptions) {
        const { instantSearchInstance } = initOptions
        renderFn({ ...this.getWidgetRenderState(initOptions), instantSearchInstance }, true)
      },
      render (renderOptions) {
        const { instantSearchInstance } = renderOptions
        renderFn({ ...this.getWidgetRenderState(renderOptions), instantSearchInstance }, false)
      },
      dispose (disposeOptions) {
        unmountFn()
      },
      getWidgetRenderState (renderOptions) {
        const { results, helper, instantSearchInstance } = renderOptions

        if (!has('sendEvent', connectorState)) {
          connectorState.sendEvent = (eventType, facetValue, eventName = 'Filter Applied') => {
            if (helper.state.isFacetRefined(attribute, facetValue)) {
              instantSearchInstance.sendEventToInsights({
                insightsMethod: 'clickedFilters',
                widgetType: this.$$type,
                eventType,
                payload: { eventName, index: helper.getIndex(), filters: [`${attribute}:${facetValue}`] },
                attribute
              })
            }
          }
        }

        if (!has('refine', connectorState)) {
          connectorState.refine = (value) => {
            connectorState.sendEvent('click', value)
            helper.toggleFacetRefinement(attribute, value).search()
          }
        }

        if (!has('clear', connectorState)) {
          connectorState.clear = () => {
            helper.removeDisjunctiveFacetRefinement(attribute).search()
          }
        }

        if (results) {
          toggleShowMore = createToggleShowMore(renderOptions, this)
        }

        const items = (results && results.getFacetValues(attribute, { sortBy: sortBy || ['isRefined', 'name:asc'] })) || []

        return {
          items,
          refine: connectorState.refine,
          clear: connectorState.clear,
          sendEvent: connectorState.sendEvent,
          limit: connectorState.limit,
          canLimit: connectorState.canLimit,
          showAll: connectorState.showAll,
          toggleShowAll: cachedToggleShowMore(),
          widgetParams
        }
      },
      getRenderState (renderState, renderOptions) {
        return assocPath(['customRefinementList', attribute], this.getWidgetRenderState(renderOptions), renderState)
      },
      getWidgetUiState (uiState, { searchParameters }) {
        return assocPath(['customRefinementList', attribute], searchParameters.getDisjunctiveRefinements(attribute), uiState)
      },
      getWidgetSearchParameters (searchParameters, { uiState }) {
        const values = uiState.customRefinementList?.[attribute]
        const state = searchParameters
          .clearRefinements(attribute)
          .addDisjunctiveFacet(attribute)
          .setQueryParameter('maxValuesPerFacet', 1000)

        if (!values) { return state }

        return reduce((acc, curr) => acc.addDisjunctiveFacetRefinement(attribute, curr), state, values)
      }
    }
  }
}
