import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.pushDataLayerEcommerce()
    this.pushDataLayerImpressions()
  }

  pushDataLayerEcommerce () {
    const purchase = JSON.parse(this.data.get('datalayer-ecommerce'))
    const value = purchase.actionField.revenue
    const currency = 'EUR'

    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: 'ce_purchase',
      ecommerce: { currency, value, purchase }
    })
  }

  pushDataLayerImpressions () {
    const attributes = JSON.parse(this.data.get('datalayer-buyer-purchase-completed'))

    dataLayer.push({
      event: 'buyer-purchase-completed',
      ...attributes
    })
  }
}
