import { Controller } from 'stimulus'
import { attr } from 'utils/dom'

export default class extends Controller {
  static targets = ['label', 'checkbox']

  connect () {
    this.toggleHoverText(this.checkboxTarget.checked)
  }

  toggle () {
    const checkbox = this.checkboxTarget
    const url = attr('data-url', checkbox)

    checkbox.disabled = true

    Rails.ajax({
      url,
      type: 'patch',
      success: ({ state }) => {
        checkbox.disabled = false
        checkbox.checked = state
        this.toggleHoverText(state)
      },
      error: () => {
        checkbox.disabled = false
        checkbox.checked = !checkbox.checked
      }
    })
  }

  toggleHoverText (value) {
    if (value) {
      this.labelTarget.removeAttribute('title')
    } else {
      this.labelTarget.setAttribute(
        'title',
        attr('data-title', this.labelTarget)
      )
    }
  }
}
