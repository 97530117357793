import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['selectedCountryLink', 'currentSelectedCountry', 'chooseLocationButton']

  connect () {
    this.triggerModal()
    document.querySelector('.modal-backdrop').classList.remove('modal-backdrop')

    this.countryLinks = this.selectedCountryLinkTargets
    this.selectedCountry = this.currentSelectedCountryTarget
    this.locationButton = this.chooseLocationButtonTarget

    this.countryLinks.forEach((link) => {
      if (this.selectedCountry.dataset.locale === link.dataset.locale) {
        this.locationButton.setAttribute('data-href', link.href)
      }
      link.addEventListener('click', this.selectCountry.bind(this))
    })

    this.locationButton.addEventListener(
      'click',
      this.redirectToCountry.bind(this)
    )
  }

  triggerModal () {
    const triggerButton = document.querySelector('#popupTriggerBtn')
    if (triggerButton) {
      triggerButton.click()
    }
  }

  selectCountry (event) {
    event.preventDefault()

    this.countryLinks.forEach((link) => {
      Array.from(link.children).forEach((child) => {
        child.classList.remove('fw-semibold')
      })
    })

    const clickedLink = event.currentTarget
    const clickedChild = clickedLink.children[1]
    clickedChild.classList.add('fw-semibold')

    const newLocaleText = clickedLink.textContent.trim()
    const newHref = clickedLink.getAttribute('href')

    this.selectedCountry.textContent = newLocaleText
    this.locationButton.setAttribute('data-href', newHref)
  }

  redirectToCountry () {
    const redirectUrl = this.locationButton.getAttribute('data-href')
    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  }
}
