import { Controller } from 'stimulus'
import { stringify } from 'utils/qs'
import { attr } from 'utils/dom'
import { forEach } from 'utils'

export default class extends Controller {
  static targets = ['dropdownAmount', 'itemsAmount', 'purchasePrice', 'deliveryPrice', 'addToCartButton']

  changeAmount (event) {
    const amount = parseInt(event.target.textContent)
    this.setDropdownAmount(amount)
    this.setItemPrice(amount)
    this.fetchDeliveryPrices(amount)
  }

  setDropdownAmount (amount) {
    this.itemsAmountTarget.value = amount
    forEach((dropdownAmount) => { dropdownAmount.textContent = amount }, this.dropdownAmountTargets)
  }

  setItemPrice (amount) {
    const initialPrice = this.purchasePriceTarget.dataset.initial
    const updatedPrice = initialPrice * amount
    this.purchasePriceTarget.textContent = this.currencyFormat(updatedPrice)
  }

  fetchDeliveryPrices (amount) {
    const queryParams = stringify({ amount })
    fetch(window.location.pathname + '/delivery_fee?' + queryParams)
      .then((response) => response.json())
      .then((json) => {
        forEach((target) => {
          const key = attr('data-delivery-id', target)
          target.innerText = json[key]
        }, this.deliveryPriceTargets)
      })
  }

  currencyFormat (price) {
    const formatter = new Intl.NumberFormat('de', { style: 'currency', currency: 'EUR' })
    return formatter.format(price)
  }

  addToCart () {
    this.pushCEAddToCart()
  }

  pushCEAddToCart () {
    const data = JSON.parse(attr(`data-${this.identifier}-ecommerce`, this.element))
    const quantity = this.amount

    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: 'ce_addtocart',
      ecommerce: {
        value: data.price,
        currency: 'EUR',
        quantity,
        add: {
          products: [{ ...data, quantity }]
        }
      }
    })
  }

  get amount () {
    return parseInt(this.itemsAmountTarget.value)
  }
}
