import { Controller } from 'stimulus'
import { addEventListener, throttle } from 'utils'
import { el } from 'utils/dom'

const iconHTML = `
<svg width="100%" height="100%" viewBox="0 0 42 42" xmlns="http://www.w3.org/2000/svg" fill="none">
  <path fill="currentColor" d="m19.5,40c0,0.8284 0.6716,1.5 1.5,1.5c0.8284,0 1.5,-0.6716 1.5,-1.5l-3,0zm2.5607,-39.06066c-0.5858,-0.58579 -1.5356,-0.58579 -2.1214,0l-9.5459,9.54596c-0.58579,0.5858 -0.58579,1.5355 0,2.1213c0.58579,0.5858 1.53553,0.5858 2.12132,0l8.48528,-8.48528l8.4853,8.48528c0.5858,0.5858 1.5355,0.5858 2.1213,0c0.5858,-0.5858 0.5858,-1.5355 0,-2.1213l-9.5459,-9.54596zm0.4393,39.06066l0,-38l-3,0l0,38l3,0z"/>
</svg>
`

/**
 * Jump-up button controller. It will append button to the given container. Container should not append other elements
 * after rendered button, because it's using sticky positioning.
 *
 * @example Usage
 *  div.container data-controller="jump-up"
 */
export default class extends Controller {
  static targets = ['buttonContainer']

  connect () {
    const handleScroll = throttle(this.handleScroll.bind(this), 100)
    this.removeScrollListener = addEventListener(window, 'scroll', handleScroll, true)
  }

  disconnect () {
    this.removeScrollListener()
  }

  addButton () {
    this.element.append(
      el('div.jump-up-container', { [`data-${this.identifier}-target`]: 'buttonContainer' },
        el('button.jump-up-btn', { 'data-action': `${this.identifier}#performScroll`, innerHTML: iconHTML })
      )
    )
  }

  performScroll () {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  removeButton () {
    if (this.isRemoving) { return }

    this.isRemoving = true

    this.buttonContainerTarget.classList.add('jump-up-container-remove')
    setTimeout(() => {
      this.buttonContainerTarget.remove()
      this.isRemoving = false
    }, 150) // delay should be in sync with animation time
  }

  handleScroll () {
    this.showButton = this.scrolledAmount > this.screenHeight
  }

  set showButton (value) {
    if (value === this.showButton) { return }
    value ? this.addButton() : this.removeButton()
  }

  get showButton () {
    return this.hasButtonContainerTarget
  }

  get scrolledAmount () {
    const { y } = this.element.getBoundingClientRect()
    return -y
  }

  get screenHeight () {
    return window.innerHeight
  }
}
