import { init } from '@sentry/browser'

const initializeSentry = () => {
  const enabled = parseInt(window.env.SENTRY_FE_ENABLED)
  const sentryKey = window.env.SENTRY_KEY

  if (!enabled || !sentryKey) { return }

  init({
    dsn: `https://${sentryKey}@o1060335.ingest.sentry.io/6049791`,
    tracesSampleRate: 0
  })
}

export { initializeSentry }
export { captureMessage } from '@sentry/browser'
