import { Controller } from 'stimulus'
import { Offcanvas } from 'bootstrap'
import { throttle, addEventListener, ScreenSize } from 'utils'

export default class extends Controller {
  connect () {
    this.handleResize = throttle(this.handleResize.bind(this), 100)
    this.removeResizeHandler = addEventListener(window, 'resize', this.handleResize)
    this.isActive && this.adjustDrawerOffset()
  }

  disconnect () {
    this.removeResizeHandler()
  }

  handleResize () {
    if (this.isActive) {
      return this.adjustDrawerOffset()
    }

    if (this.shown) {
      Offcanvas.getInstance(this.element).hide()
    }
  }

  adjustDrawerOffset () {
    const { height } = document.querySelector('nav.navigation').getBoundingClientRect()
    this.element.style.top = `${height}px`
  }

  get shown () {
    return this.element.classList.contains('show')
  }

  get isActive () {
    return new ScreenSize(window) <= ScreenSize.md
  }
}
