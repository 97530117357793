import { equals, none, type, assoc, includes, isEmpty, keys, reduce, uniq } from 'ramda'

const arrayEquals = (a, b) => {
  const values = uniq([...a, ...b])
  return none((value) => includes(value, a) !== includes(value, b), values)
}

/**
 * Compare two objects and make a diff, currently supports primitive values and arrays.
 * Arrays order doesn't make any difference, meaning that { foo: [1, 2] } and { foo: [2, 1] } are considered equal
 * @param {Object} a
 * @param {Object} b
 * @return {Object}
 */
export default (a, b) => {
  if (isEmpty(a) && isEmpty(b)) { return null }
  const allKeys = uniq([...keys(a), ...keys(b)])
  const reducer = (result, key) => {
    const valueA = a[key]
    const valueB = b[key]
    const typeA = type(valueA)
    const typeB = type(valueB)

    if (typeA !== typeB) {
      return assoc(key, [valueA, valueB], result)
    }

    switch (typeA) {
      case 'String':
      case 'Number':
      case 'Boolean':
        return equals(valueA, valueB) ? result : assoc(key, [valueA, valueB], result)
      case 'Array':
        return arrayEquals(valueA, valueB) ? result : assoc(key, [valueA, valueB], result)
    }

    return result
  }

  return reduce(reducer, {}, allKeys)
}
