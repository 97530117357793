import { curry, reduce, keys, sort } from 'ramda'

/**
 * Returns new object with keys sorted according to sortFn
 * @param {Function} sortFn typical comparator returning -1, 0 or 1
 * @param {Object} sortObj object to sort
 * @return {Object}
 */
export default curry((sortFn, sortObj) => {
  const reducer = (acc, key) => {
    acc[key] = sortObj[key]
    return acc
  }

  return reduce(reducer, {}, sort(sortFn, keys(sortObj)))
})
