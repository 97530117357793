/**
 * A simple class helping to deal with screen width, which breakpoints and their names
 * taken from official docs: https://getbootstrap.com/docs/5.0/layout/breakpoints/
 *
 * @example
 *   // don't do this meaningless stuff:
 *   const isMobile = window.innerWidth < 768
 *   // go with this fancy stuff!
 *   const isMobile = new ScreenSize(500) < ScreenSize.md
 *   // you can also pass window object here!
 *   const isTablet = new ScreenSize(window) == ScreenSize.md
 *   // or make custom sizes!
 *   const isSameBreakpoint = new ScreenSize(600) == new ScreenSize(650)
 */
class ScreenSize {
  static sizes = ['xs', 'sm', 'md', 'lg', 'xl', 'xxl']
  static breakpoints = [[0, 576], [576, 768], [768, 992], [992, 1200], [1200, 1400], [1400, Infinity]]

  /**
   * @see ScreenSize#size
   * @param size
   */
  constructor (size) {
    this.size = size
  }

  get breakpoints () {
    return this.constructor.breakpoints
  }

  get sizes () {
    return this.constructor.sizes
  }

  /**
   * @return {String} breakpoint
   */
  get size () {
    return this._size
  }

  /**
   * @param {Window, String, Number} value window object or breakpoint or screen width value
   */
  set size (value) {
    if (typeof value === 'string') {
      const newSize = this.sizes.find((size) => size === value)
      if (!newSize) { throw new Error(`invalid size: ${value}`) }
      return (this._size = newSize)
    }

    if (value === window) {
      value = value.innerWidth
    }

    for (let i = 0; i < this.breakpoints.length; i++) {
      const [min, max] = this.breakpoints[i]
      if (value >= min && value < max) {
        this._size = this.sizes[i]
        return this._size
      }
    }

    throw new Error(`invalid size: ${value}`)
  }

  valueOf () {
    return this.constructor.sizes.indexOf(this.size)
  }
}

ScreenSize.xs = new ScreenSize('xs')
ScreenSize.sm = new ScreenSize('sm')
ScreenSize.md = new ScreenSize('md')
ScreenSize.lg = new ScreenSize('lg')
ScreenSize.xl = new ScreenSize('xl')
ScreenSize.xxl = new ScreenSize('xxl')

export default ScreenSize
