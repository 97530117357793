import { Controller } from 'stimulus'
import { addEventListener } from 'utils'
import { hasAttr } from 'utils/dom'

/**
 * Show More component.
 *
 * @example Usage
 *  .show-more(data-controller="show-more")
 *    .show-more-content
 *      Lorem Ipsum
 *    %a.show-more-trigger(data-action="show-more#open")
 *      Show More
 *
 * It also implements auto-open feature. When used inside collapse and show-more wasn't opened,
 * closing the collapse will automatically open the show more. That is made to reduce amount of
 * clicks required for user.
 *
 * @example Auto-open feature
 *  .show-more(data-controller="show-more" data-show-more-auto-open)
 *    .show-more-content
 *      Lorem Ipsum
 *    %a.show-more-trigger(data-action="show-more#open")
 *      Show More
 */
export default class extends Controller {
  static targets = ['content']

  connect () {
    if (this.contentTargetFits) {
      return this.open()
    }

    if (this.autoOpen) {
      this.removeCollapseListener = addEventListener(document, 'hidden.bs.collapse', this.handleAutoOpen.bind(this))
    }
  }

  disconnect () {
    this.removeCollapseListener && this.removeCollapseListener()
  }

  open () {
    this.element.classList.add('show-more-opened')
  }

  get contentTargetFits () {
    if (!this.hasContentTarget) { return false }

    const { clientHeight, scrollHeight } = this.contentTarget
    return clientHeight >= scrollHeight
  }

  handleAutoOpen (e) {
    const { target } = e
    if (!target.contains(this.element)) { return }

    this.open()
    this.removeCollapseListener()
  }

  get autoOpen () {
    return hasAttr(`data-${this.identifier}-auto-open`, this.element)
  }
}
