import { Controller } from 'stimulus'

export default class extends Controller {
  static classes = ['show']

  toggle () {
    this.element.classList.toggle(this.showClass)
  }

  hide () {
    this.element.classList.remove(this.showClass)
  }

  show () {
    this.element.classList.add(this.showClass)
  }
}
