import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['submit']

  enableSubmitButton () {
    this.submitTargets.forEach((button) => { button.disabled = false })
  }

  disableSubmitButton () {
    this.submitTargets.forEach((button) => { button.disabled = true })
  }
}
