import { addIndex, reduce, forEach, find } from 'ramda'

export { default as addEventListener } from './add_event_listener'
export { default as caseInsensitiveCompare } from './case_insensitive_compare'
export { default as objDiff } from './obj_diff'
export { default as parseCookie } from './parse_cookie'
export { default as presence } from './presence'
export { default as ScreenSize } from './screen_size'
export { default as sortObj } from './sort_obj'
export { default as throwError } from './throw_error'
export { default as transition } from './transition'

export { throttle } from 'lodash'

export {
  all,
  assoc,
  assocPath,
  call,
  curry,
  dissoc,
  dissocPath,
  filter,
  find,
  forEach,
  forEachObjIndexed,
  has,
  hasPath,
  head,
  includes,
  init,
  isEmpty,
  isNil,
  keys,
  last,
  map,
  mergeRight,
  omit,
  path,
  pathOr,
  pick,
  prepend,
  prop,
  propEq,
  propOr,
  props,
  reduce,
  split,
  tail,
  thunkify,
  type,
  uniq,
  without
} from 'ramda'

const findIndexed = addIndex(find)
const reduceIndexed = addIndex(reduce)
const forEachIndexed = addIndex(forEach)

export {
  findIndexed,
  forEachIndexed,
  reduceIndexed
}
