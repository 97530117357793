import { Controller } from 'stimulus'
import { ScreenSize, addEventListener } from 'utils'

export default class extends Controller {
  static targets = ['trigger', 'content']

  connect () {
    this.removeListener = addEventListener(document, 'navigation--desktop-item.click', this.handleClick.bind(this))
  }

  disconnect () {
    this.removeListener()
  }

  triggerClick (e) {
    if (this.hasContentTarget && this.notDesktop) {
      e.preventDefault()
    }

    this.triggerTarget.classList.toggle('navigation__shop-menu__item__trigger-active')

    const event = new CustomEvent('navigation--desktop-item.click', { bubbles: true })
    this.triggerTarget.dispatchEvent(event)
  }

  // when other menu was opened, we need to close our
  handleClick ({ target }) {
    if (target === this.triggerTarget) { return }
    if (!this.triggerTarget.classList.contains('navigation__shop-menu__item__trigger-active')) { return }

    this.triggerTarget.classList.remove('navigation__shop-menu__item__trigger-active')
  }

  get notDesktop () {
    return new ScreenSize(window) <= ScreenSize.md
  }
}
