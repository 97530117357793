import { el } from 'utils/dom'
import { nanoid } from 'nanoid'

export default () => {
  return class {
    static id = `id${nanoid(4)}`

    constructor () {
      const id = `${this.constructor.id}-${nanoid(10)}`
      this.inputNode = el('input.form-check-input', { type: 'checkbox', id })
      this.labelNode = el('label.form-check-label', { for: id })

      this.el = el('li.form-check', [
        this.inputNode,
        this.labelNode
      ])
    }

    update (data) {
      const { name, value, isRefined } = data

      this.labelNode.textContent = name
      this.inputNode.checked = isRefined
      this.inputNode.value = value || name
    }
  }
}
