import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'sellerPrice',
    'result',
    'shippingDepth',
    'shippingHeight',
    'shippingWidth',
    'depth',
    'height',
    'width',
    'title',
    'brandName'
  ]

  static values = {
    url: String
  }

  connect () {
    this.refresh()
  }

  refresh () {
    fetch(this.url)
      .then(response => response.text())
      .then(html => (this.resultTarget.innerHTML = html))
      .catch(error => console.log('no result', error))
  }

  get url () {
    const url = new URL(this.urlValue)
    url.searchParams.append('item[seller_price]', this.sellerPriceTarget.value)
    url.searchParams.append('item[depth]', this.depthTarget.value)
    url.searchParams.append('item[height]', this.heightTarget.value)
    url.searchParams.append('item[width]', this.widthTarget.value)
    url.searchParams.append('item[shipping_depth]', this.shippingDepthTarget.value)
    url.searchParams.append('item[shipping_height]', this.shippingHeightTarget.value)
    url.searchParams.append('item[shipping_width]', this.shippingWidthTarget.value)
    return url
  }

  submit () {
    dataLayer.push({
      title: this.titleTarget.value,
      price: this.sellerPriceTarget.value,
      brandName: this.brandNameTarget.value,
      event: 'seller-item-create-click'
    })
  }
}
