import { Controller } from 'stimulus'

export default class extends Controller {
  connect () {
    this.loadGoogleReviews()
  }

  loadGoogleReviews () {
    const REVIEW_SCRIPT_ID = 'reviewScript'
    const THRESHOLD_GAP = 300
    const reviewScriptTag = document.getElementById(REVIEW_SCRIPT_ID)
    if (reviewScriptTag) { return }

    const footer = document.getElementsByTagName('footer')[0]
    const element = document.documentElement
    const footerVisible = element.scrollHeight - element.scrollTop - THRESHOLD_GAP < element.clientHeight + footer.offsetHeight
    if (footerVisible) {
      const head = document.getElementsByTagName('head')[0]
      const js = document.createElement('script')

      js.id = REVIEW_SCRIPT_ID
      js.type = 'text/javascript'
      js.src = 'https://apps.elfsight.com/p/platform.js'
      head.appendChild(js)
    }
  }
}
