import { caseInsensitiveCompare, isEmpty, sortObj } from 'utils/index'
import { stringify } from 'utils/qs'

const alphabeticallySortObj = sortObj(caseInsensitiveCompare)

/**
 * Make request using Turbolinks to same uri with new queryParameters, if queryParameters is empty - clears entire query string
 * @param {Object} queryParams
 * @param {String, null} anchor
 * @return {void}
 */
export default (queryParams, anchor = null) => {
  let path = window.location.pathname

  if (!isEmpty(queryParams)) {
    const orderedQueryParams = alphabeticallySortObj(queryParams)
    path += `?${stringify(orderedQueryParams)}`
  }

  if (anchor) {
    path += `#${anchor}`
  }

  Turbolinks.visit(path)
}
