import { Controller } from 'stimulus'

export default class extends Controller {
  static classes = ['disabled']

  disable () {
    document.body.classList.add(this.disabledClass)
  }

  enable () {
    document.body.classList.remove(this.disabledClass)
  }

  toggle () {
    document.body.classList.toggle(this.disabledClass)
  }
}
