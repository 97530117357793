import { Controller } from 'stimulus'
import { reduce, findIndexed, forEach, addEventListener, call } from 'utils'

export default class extends Controller {
  static targets = ['source']

  connect () {
    this.eventRemovals = []
    this.targetShown = reduce((acc, { media }) => {
      if (!media) { return [...acc, true] }

      const mediaQuery = window.matchMedia(media)
      const index = acc.length
      const removeEvent = addEventListener(mediaQuery, 'change', (event) => {
        this.targetShown[index] = event.matches
        this.pickVisibleSource()
      })
      this.eventRemovals.push(removeEvent)

      return [...acc, mediaQuery.matches]
    }, [], this.sourceTargets)
  }

  disconnect () {
    forEach(call, this.eventRemovals)
  }

  pickVisibleSource () {
    const currentSource = findIndexed((_source, index) => this.targetShown[index], this.sourceTargets)
    this.element.src = currentSource.src
  }
}
