import { append, conditionalToggle, el, list } from 'utils/dom'
import { addEventListener, prepend, find, propEq, type } from 'utils'
import buildListItemComponent from './build_list_item_component'

export default function createCustomRefinementListRenderer (widgetParams) {
  const { container } = widgetParams
  const containerNode = type(container) === 'String' ? document.querySelector(container) : container
  const listNode = el('ul.no-style.mb-0')
  const listComponent = list(listNode, buildListItemComponent(), 'name')
  const showMoreButton = el('button.btn.btn-link.btn-sm.mt-1.ms-4', { type: 'button' })
  let cachedToggleShowAll = null
  const callToggleShowAll = () => {
    cachedToggleShowAll()
  }

  const allItem = {
    name: 'Alle',
    isRefined: true
  }

  return {
    render (renderParams, isFirstRender) {
      let { items } = renderParams
      const itemsCount = items.length

      if (itemsCount) {
        allItem.isRefined = find(propEq('isRefined', true), items) === undefined
      }

      const { refine, clear, limit, canLimit, showAll, toggleShowAll } = renderParams
      cachedToggleShowAll = toggleShowAll
      // limit + 1 here is for this stupid situation when you have `show more (+1)` button,
      // it's meaningless to display button in this case cuz it doesn't save space: you can render that one
      // additional item in place of button,
      // so here we start hiding items only when at least 2 items could be hidden
      const isLimitApplicable = itemsCount > (limit + 1)

      if (isFirstRender) {
        append(containerNode, listNode)

        addEventListener(listNode, 'change', (event) => {
          const { target } = event
          target.value === 'Alle' ? clear() : refine(target.value)
        })

        if (canLimit) {
          append(containerNode, showMoreButton)
          addEventListener(showMoreButton, 'click', callToggleShowAll)
        }
      }

      if (!showAll && isLimitApplicable) {
        items = items.slice(0, limit)
      }

      if (canLimit) {
        conditionalToggle('d-none', showMoreButton.classList, !isLimitApplicable)
        showMoreButton.textContent = !showAll ? `(+ ${itemsCount - limit}) Mehr` : 'Weniger'
      }

      items = prepend(allItem, items)
      listComponent.update(items)
    },

    dispose () {
      containerNode.innerHTML = ''
    }
  }
}
