import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'
import heic2any from 'heic2any'

export default class extends Controller {
  static targets = ['preview', 'fileField', 'progress']

  connect () {
    this.processing = false
  }

  set processing (value) {
    this.fileFieldTarget.disabled = value

    if (value) {
      this.element.classList.add('loading')
    } else {
      this.element.classList.remove('loading')
    }

    return (this._processing = value)
  }

  get processing () {
    return this._processing
  }

  readFile () {
    Array.from(this.files).forEach(async (file) => {
      if (this.validImageType(file.type)) {
        this.processImage(file)
      } else if (this.validHeicImageType(file.type)) {
        this.processing = true
        const convertedFile = await this.convertHEIC(file)
        this.processing = false
        if (!convertedFile) { return }
        this.processImage(convertedFile)
      } else if (this.validVideoType(file.type)) {
        this.processVideo(file)
      } else {
        console.log('wrong file type', file.type)
      }
    })
  }

  processImage (file) {
    this.previewImage(file)
    this.uploadFile(file)
  }

  processVideo (file) {
    this.previewVideo(file)
    this.uploadFile(file)
  }

  previewVideo (file) {
    this.element.classList.add('selected')
  }

  previewImage (file) {
    this.element.classList.add('selected')
    const reader = new FileReader()
    reader.onload = () => {
      this.previewTarget.src = reader.result
      this.previewTarget.classList.remove('d-none')
    }
    reader.readAsDataURL(file)
  }

  /**
   * Convert .heic file to .jpeg
   * @param {File} file original .heic file
   * @return {Promise<File|null>} converted file with original properties and applied correct extension to file name
   *   or null if process was unsuccessful
   */
  convertHEIC (file) {
    const type = 'image/jpeg'
    const extension = '.jpeg'
    const { name, lastModified } = file

    const options = {
      blob: file,
      toType: type,
      quality: 0.9
    }

    return new Promise((resolve) => {
      const handleResolve = (blob) => resolve(new File([blob], name + extension, { lastModified, type }))
      const handleError = (error) => {
        resolve(null)
        console.log(error)
      }

      heic2any(options).then(handleResolve, handleError)
    })
  }

  uploadFile (file) {
    const url = this.fileFieldTarget.dataset.directUploadUrl
    const upload = new DirectUpload(file, url, this)
    upload.create((error, blob) => {
      // Handle the error
      if (error) { return console.log(error) }

      // Add an appropriately-named hidden input to the form with a
      //  value of blob.signed_id so that the blob ids will be
      //  transmitted in the normal upload flow
      const hiddenField = document.createElement('input')
      hiddenField.setAttribute('type', 'hidden')
      hiddenField.setAttribute('value', blob.signed_id)
      hiddenField.name = this.fileFieldTarget.name
      document.querySelector('form').appendChild(hiddenField)
      this.fileFieldTarget.value = ''
    })
  }

  directUploadWillStoreFileWithXHR (request) {
    request.upload.addEventListener('progress', event => this.directUploadDidProgress(event))
  }

  directUploadDidProgress (event) {
    console.log(event)
    this.progressTarget.classList.remove('d-none')
    this.progressTarget.style.height = `${100 - (event.loaded / event.total * 100)}%`
    if (event.loaded !== event.total) {
      this.formController.disableSubmitButton()
    } else {
      this.formController.enableSubmitButton()
    }
  }

  get formController () {
    return this.application.getControllerForElementAndIdentifier(this.element.closest('form'), 'edit-images')
  }

  get files () {
    return this.fileFieldTarget.files
  }

  validImageType (type) {
    return [
      'image/jpg',
      'image/jpeg',
      'image/png',
      'image/webp'
    ].includes(type)
  }

  validHeicImageType (type) {
    return [
      'image/heic',
      'image/heif'
    ].includes(type)
  }

  validVideoType (type) {
    return [
      'video/mp4',
      'video/ogg',
      'video/webm',
      'video/quicktime',
      'video/x-msvideo'
    ].includes(type)
  }
}
