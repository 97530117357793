import { type, assoc, props } from 'utils'
import { setChildren, el } from 'utils/dom'
import Input from './input'
import RangeLimit from './range_limit'

export default function createCustomRefinementListRenderer (widgetParams) {
  const { container, precision = 0 } = widgetParams
  const containerNode = type(container) === 'String' ? document.querySelector(container) : container
  const step = Math.pow(10, precision)

  const limiter = new RangeLimit()
  const minInput = new Input('min', limiter, { type: 'number', step })
  const maxInput = new Input('max', limiter, { type: 'number', step })
  const delimeter = el('span', 'zu')

  const toStateValue = (value, limit) => Number.isFinite(value) && value !== limit ? value : undefined

  return {
    render (renderParams, isFirstRender) {
      const { start, range, refine } = renderParams
      const [minValue, maxValue] = start
      const { min, max } = range
      const state = {
        min: toStateValue(minValue, min),
        max: toStateValue(maxValue, max)
      }

      const onChange = (key, value) => refine(props(['min', 'max'], assoc(key, value, state)))

      limiter.state = state
      limiter.range = range

      minInput.update(state, onChange, { placeholder: min, min, max })
      maxInput.update(state, onChange, { placeholder: max, min, max })

      if (isFirstRender) {
        setChildren(containerNode, [minInput, delimeter, maxInput])
      }
    },

    dispose () {
      containerNode.innerHTML = ''
    }
  }
}
