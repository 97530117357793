import connectCustomRefinementList from './connector'
import createCustomRefinementListRenderer from './renderer'

export default function customRefinementList (params) {
  const { container } = params
  const { render, dispose } = createCustomRefinementListRenderer({ container })

  const createWidget = connectCustomRefinementList(render, dispose)

  return { ...createWidget(params), $$widgetType: 'cocoli.refinementList' }
}
