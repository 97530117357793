import { Controller } from 'stimulus'
import Masonry from 'masonry-layout'

import cookies from 'services/cookies'
import { includes, forEach, find, addEventListener } from 'utils'
import { attr, hasAttr } from 'utils/dom'

const INDEX_NAME = window.env.ALGOLIA_INDEX_NAME

export default class extends Controller {
  static targets = ['link', 'image', 'masonry', 'card']

  connect () {
    this.listName = attr('data-list-name', this.element)
    this.queryId = attr('data-query-id', this.element)

    this.removeScrollListener = this.addScrollListener()

    this.imageLoaded()
    this.pushDataLayerImpressions()
  }

  disconnect () {
    this.masonry.destroy()
    this.removeScrollListener()
  }

  /**
   * Adds listener that intent to solve jumping issue with masonry grid.
   * When masonry layout is applied, the container height change might cause page to jump up, so it not work well
   * with something like link anchors.
   * @return {Function}
   */
  addScrollListener () {
    // !!!WARNING!!!
    // our attempt to solve scroll issues by scrolling user back somehow makes system tests flaky, such as:
    // - click on links sometimes doesn't work
    // - unable to locate elements that are on a page
    // - even purchase might be created with wrong values
    // this is why for testing this "fix" is disabled
    if (process.env.RAILS_ENV === 'test') {
      return () => {}
    }

    let pageOffsetWas = window.pageYOffset
    const removeScrollListener = addEventListener(window, 'scroll', () => {
      const pageOffset = window.pageYOffset

      // Masonry adds `style` attribute with height, so it's the trigger for us
      if (hasAttr('style', this.element)) {
        // once masonry changed element height, we need to check if scroll was applied because of that
        if (Math.abs(pageOffsetWas - pageOffset) > 2) {
          // and if it does - scroll back to previous position
          window.scroll(0, pageOffsetWas)
        }
        return removeScrollListener()
      }

      pageOffsetWas = pageOffset
    })

    return removeScrollListener
  }

  pushDataLayerImpressions () {
    if (this.linkTargets.length === 0) return

    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: 'ce_impression',
      ecommerce: {
        currencyCode: 'EUR',
        impressions: this.linkTargets.slice(0, 10).map(e => this.getDataLayerAttributes(e))
      }
    })
  }

  pushDataLayerClick (e) {
    const card = find((card) => card.contains(e.currentTarget), this.cardTargets)

    const dataLayerPayloadJSON = attr('data-datalayer', e.currentTarget)
    const dataLayerPayload = this.parseData(dataLayerPayloadJSON)

    this.pushGTMProductClickEvent(card, dataLayerPayload)
    this.pushAlgoliaProductClickEvent(card)
  }

  pushGTMProductClickEvent (card, data) {
    if (!data) { return }

    data.list = this.listName
    data.position = this.cardTargets.indexOf(card) + 1

    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: 'ce_productclick',
      ecommerce: {
        click: {
          actionField: { list: data.list },
          products: [data]
        }
      }
    })
  }

  pushAlgoliaProductClickEvent (card) {
    if (!this.queryId) { return }

    dataLayer.push({ algolia: null })
    dataLayer.push({
      event: 'plp_product_click_after_search',
      algolia: {
        click: {
          eventName: 'PLP; Product click after search',
          indexName: INDEX_NAME,
          position: this.cardTargets.indexOf(card) + 1,
          objectIDs: [attr('data-item-id', card)],
          queryID: this.queryId,
          userToken: cookies.signed['uuid'] // eslint-disable-line
        }
      }
    })
  }

  parseData (data) {
    if (!data) return
    return JSON.parse(data)
  }

  imageLoaded () {
    if (this.imageTargets.some(image => !image.complete)) { return }

    this.refreshMasonry()
    this.removePlaceholders()
  }

  loadMore (e) {
    const link = e.currentTarget
    link.classList.add('disabled')
    link.setAttribute('aria-disabled', true)
  }

  moreLoaded (e) {
    const target = e.currentTarget

    target.parentElement.remove()
    this.refreshMasonry()
  }

  refreshMasonry () {
    if (this.previousMasonryTargets) {
      forEach((el) => {
        if (includes(el, this.previousMasonryTargets)) { return }
        this.masonry.appended(el)
      }, this.masonryTargets)
    }

    this.masonry.layout()
    this.previousMasonryTargets = this.masonryTargets
  }

  get masonry () {
    if (!this._masonry) {
      this._masonry = new Masonry(this.element, {
        itemSelector: '.item-card, .item-card-pagination',
        columnWidth: '.item-sizer',
        percentPosition: true,
        transitionDuration: 0
      })
    }

    return this._masonry
  }

  removePlaceholders () {
    this.element.querySelectorAll('.placeholder').forEach((element) => {
      element.classList.remove('placeholder')
    })
  }

  getDataLayerAttributes (element) {
    return JSON.parse(element.getAttribute('data-datalayer'))
  }
}
