import { Controller } from 'stimulus'

const upKey = 38
const downKey = 40
const enterKey = 13
const navigationKeys = [upKey, downKey, enterKey]

export default class extends Controller {
  static targets = ['query', 'results', 'brand', 'brandId']
  static values = { url: String, currentResultIndex: Number }
  static classes = ['current']

  currentResultIndexValueChanged () {
    this.brandTargets.forEach((brand, index) => {
      brand.classList.toggle(this.currentClass, index === this.currentResultIndexValue)
    })
  }

  navigateResults (event) {
    if (!navigationKeys.includes(event.keyCode)) {
      return
    }

    event.preventDefault()

    switch (event.keyCode) {
      case downKey:
        this.selectNextResult()
        break
      case upKey:
        this.selectPreviousResult()
        break
      case enterKey:
        this.select()
        break
    }
  }

  selectResult (event) {
    this.currentResultIndexValue = this.brandTargets.indexOf(event.currentTarget)
    this.select()
  }

  search (event) {
    if (navigationKeys.includes(event.keyCode)) {
      return
    }
    this.currentResultIndexValue = null
    this.brandIdTarget.value = null

    fetch(this.url)
      .then(response => response.text())
      .then(html => (this.resultsTarget.innerHTML = html))
      .then(args => this.toggleResults())
  }

  select () {
    const brand = this.brandTargets[this.currentResultIndexValue].dataset
    this.brandIdTarget.value = brand.id
    this.queryTarget.value = brand.name
    this.toggleResults()
    this.resultsTarget.classList.remove('show')
  }

  selectNextResult () {
    if (this.currentResultIndexValue < this.brandTargets.length - 1) {
      this.currentResultIndexValue++
    }
  }

  selectPreviousResult () {
    if (this.currentResultIndexValue > 0) {
      this.currentResultIndexValue--
    }
  }

  toggleResults () {
    this.currentResultIndexValue = 0
    if (this.brandTargets.length) {
      this.resultsTarget.classList.add('show')
    } else {
      this.resultsTarget.classList.remove('show')
    }
  }

  get query () {
    return this.queryTarget.value
  }

  get url () {
    const url = new URL(this.urlValue)
    url.searchParams.set('query', this.query)
    return url
  }
}
