import { Controller } from 'stimulus'
import { Dropdown } from 'bootstrap'

import algoliasearch from 'algoliasearch/lite'
import instantsearch from 'instantsearch.js'
import { configure, rangeSlider } from 'instantsearch.js/es/widgets'
import { customRefinementList, customRangeInput } from 'services/instantsearch/widgets'

import { parse } from 'utils/qs'
import { visitQuery } from 'utils/uri'
import { attr } from 'utils/dom'
import {
  assoc,
  assocPath,
  caseInsensitiveCompare,
  dissoc,
  dissocPath,
  filter,
  forEach,
  includes,
  isEmpty,
  isNil,
  hasPath,
  mergeRight,
  path,
  pathOr,
  prop,
  pick,
  reduce,
  objDiff,
  throwError
} from 'utils'

const APP_ID = window.env.ALGOLIA_APP_ID
const API_KEY = window.env.ALGOLIA_API_KEY
const INDEX_NAME = window.env.ALGOLIA_INDEX_NAME
const CONDITION_ORDER = ['Neu', 'Sehr guter Zustand', 'Guter Zustand', 'Akzeptabler Zustand']
const conditionIndex = (value) => CONDITION_ORDER.indexOf(value)
const SELLER_KIND_ORDER = ['Hersteller', 'Offizieller Markenhändler', 'Privatperson', 'Vintage Händler']
const sellerKindIndex = (value) => SELLER_KIND_ORDER.indexOf(value)

export default class extends Controller {
  static targets = ['widget', 'sortForm']
  static values = {
    anchor: String,
    defaultSort: String,
    filters: String, // default filters
    filterEventName: String,
    sortEventName: String
  }

  searching = false

  connect () {
    this.currentQueryParams = parse(window.location.search)
    this.initializeSearch()
  }

  startSearch () {
    if (this.searching) { return }

    this.search.start()
    this.searching = true
  }

  apply (e) {
    e.preventDefault()
    this.sendEvents()

    const { target } = e
    this.maybeHideDropdown(target)

    const newQueryParams = mergeRight(this.otherQueryParams, this.filterQueryParams)
    visitQuery(newQueryParams, this.anchorValue)
  }

  /**
   * If "Apply" was pressed inside dropdowns, we need to programatically hide it,
   * otherwise Turbolinks would store page with opened dropdown and returning back
   * or clicking "Reset" would show page with dropdown already opened
   *
   * @param {Element} trigger apply button
   */
  maybeHideDropdown (trigger) {
    const menu = trigger.closest('.dropdown-menu')
    const dropdown = Dropdown.getInstance(menu?.previousElementSibling)
    dropdown && dropdown.hide()
  }

  reset (e) {
    e.preventDefault()
    visitQuery(this.otherQueryParams, this.anchorValue)
  }

  sendEvents () {
    const { sort: newSortValue, ...newFilters } = this.filterQueryParams
    const { sort: previousSortValue = this.defaultSortValue, ...previousFilters } =
      pick(this.filterQueryNames, this.currentQueryParams)

    if (this.hasSortFormTarget && newSortValue !== previousSortValue) {
      dataLayer.push({ event: this.sortEventNameValue, value: newSortValue })
    }

    const changes = objDiff(previousFilters, newFilters)
    if (!isEmpty(changes)) {
      dataLayer.push({ event: this.filterEventNameValue, value: newFilters })
    }
  }

  /**
   * returns object containing only params that DOES BELONG to our filters with current search state values
   * @return {Object}
   */
  get filterQueryParams () {
    const queryParams = {}
    const state = this.state()

    if (this.hasSortFormTarget) {
      Object.assign(queryParams, Object.fromEntries(new FormData(this.sortFormTarget).entries()))
    }

    this.eachWidget(({ type, attribute, queryParameterName }) => {
      const value = path([type, attribute], state)
      if (isEmpty(value) || isNil(value)) { return }
      queryParams[queryParameterName] = value
    })

    return queryParams
  }

  /**
   * returns object containing only params that ARE NOT BELONG to our filters from current query parameters
   * @return {Object}
   */
  get otherQueryParams () {
    return reduce((params, name) => dissoc(name, params), this.currentQueryParams, this.filterQueryNames)
  }

  /**
   * returns array containing names of query parameters that DOES BELONG to our filters
   * @return {[String]}
   */
  get filterQueryNames () {
    const filterQueryNames = []
    if (this.hasSortFormTarget) { filterQueryNames.push('sort') }
    this.eachWidget(({ queryParameterName }) => filterQueryNames.push(queryParameterName))
    return filterQueryNames
  }

  state (state = null) {
    state = state || this.search.getUiState()
    return state[INDEX_NAME]
  }

  initializeSearch () {
    const searchClient = algoliasearch(APP_ID, API_KEY, {})

    this.search = instantsearch({
      indexName: INDEX_NAME,
      initialUiState: {
        [INDEX_NAME]: this.getSearchState()
      },
      onStateChange: this.handleSearchStateChange.bind(this),
      searchClient
    })

    const widgets = []

    widgets.push(configure({}))
    this.eachWidget((options, el) => {
      widgets.push(this.buildWidget(el, options))
    })
    this.search.addWidgets(widgets)

    // searchStateCache is used to order initially selected items first
    this.searchStateCache = this.getSearchState()
  }

  getSearchState () {
    const state = {
      configure: {
        query: this.currentQueryParams.query || '*',
        filters: this.filtersValue
      }
    }

    this.eachWidget(({ type, attribute, queryParameterName }) => {
      const value = this.currentQueryParams[queryParameterName]
      if (!value) { return }
      state[type] = assoc(attribute, value, state[type])
    })

    return state
  }

  handleSearchStateChange ({ uiState, setUiState }) {
    setUiState(uiState)

    // here we are removing from searchStateCache an item that was previously selected,
    // so that unselecting an item that was selected when page is loaded moves and item in place in a list
    this.eachWidget(({ type, attribute }) => {
      const valueWas = path([type, attribute], this.searchStateCache)

      if (isEmpty(valueWas) || isNil(valueWas)) { return }

      const pathExists = hasPath([type, attribute], this.state(uiState))
      if (!pathExists) {
        this.searchStateCache = dissocPath([type, attribute], this.searchStateCache)
        return
      }

      let value = path([type, attribute], this.state(uiState))

      // if value is array, we need to keep in searchStateCache values that are still active
      if (Array.isArray(value)) {
        value = filter((item) => includes(item, value), valueWas)
      }

      this.searchStateCache = assocPath([type, attribute], value, this.searchStateCache)
    })
  }

  eachWidget (callback) {
    forEach((el) => callback(this.getWidgetSettings(el), el), this.widgetTargets)
  }

  getWidgetSettings (el) {
    const widgetName = attr(`data-${this.identifier}-widget`, el) // widget name
    const type = attr(`data-${this.identifier}-type`, el) || widgetName // algoliasearch state key, defaults to widget name
    const attribute = attr(`data-${this.identifier}-attribute`, el) // searchable attribute, e.g. manufacturer
    const queryParameterName = attr(`data-${this.identifier}-qparam`, el) || attribute // according query parameter, defaults to attribute

    return { widgetName, type, attribute, queryParameterName }
  }

  buildWidget (el, options) {
    const { widgetName } = options

    switch (widgetName) {
      case 'rangeSlider':
        return this.buildRangeSlider(el, options)
      case 'customRangeInput':
        return this.buildCustomRangeInput(el, options)
      case 'customRefinementList':
        return this.buildRefinement(el, options)
      default:
        throwError(`unkown widget ${widgetName}`)
    }
  }

  buildRangeSlider (container, { attribute }) {
    return rangeSlider({ container, attribute, pips: false, tooltips: false })
  }

  buildCustomRangeInput (container, { attribute }) {
    return customRangeInput({
      container,
      attribute,
      precision: 0
    })
  }

  buildRefinement (container, { attribute, type }) {
    const comparator = this.defineComparator(attribute)

    return customRefinementList({
      container,
      attribute,
      limit: 6,
      sortBy: this.sortingFunction(type, attribute, comparator)
    })
  }

  defineComparator (attribute) {
    switch (attribute) {
      case 'status_of_condition': return this.conditionComparator
      case 'seller_kind': return this.sellerKindComparator
      default: return caseInsensitiveCompare
    }
  }

  sortingFunction (type, attribute, comparator) {
    const getValue = prop('name')
    const getValues = pathOr([], [type, attribute])

    // sort items which was selected on initial load first
    return (objectA, objectB) => {
      const valueA = getValue(objectA)
      const valueB = getValue(objectB)
      const values = getValues(this.searchStateCache)

      if (!isEmpty(values)) {
        const isIncludedA = includes(valueA, values)
        const isIncludedB = includes(valueB, values)

        if (isIncludedA && !isIncludedB) { return -1 }
        if (!isIncludedA && isIncludedB) { return 1 }
      }

      return comparator(valueA, valueB)
    }
  }

  conditionComparator (valueA, valueB) {
    return conditionIndex(valueA) - conditionIndex(valueB)
  }

  sellerKindComparator (valueA, valueB) {
    return sellerKindIndex(valueA) - sellerKindIndex(valueB)
  }
}
