import { Controller } from 'stimulus'
import { addEventListener, ScreenSize } from 'utils'
import { attr } from 'utils/dom'

const addIntersectionObserver = (target, callback) => {
  const observer = new IntersectionObserver(callback)
  observer.observe(target)
  return observer.disconnect
}

export default class extends Controller {
  static targets = ['floatingAddToCart', 'floatingAddToCartBoundary']

  connect () {
    this.pushDataLayerEcommerce()

    this.floatingAddToCartTarget.style.position = 'sticky'
    this.adjustFloatingPosition = this.adjustFloatingPosition.bind(this)

    // we rely on screen size to compute element appearance
    this.removeResizeHandler = addEventListener(window, 'resize', this.handleResize.bind(this), true)

    // we also need to track scroll events
    this.removeScrollHandler = addEventListener(window, 'scroll', this.adjustFloatingPosition)

    // finally we need to keep track of boundary intersection to react on accordion open/close or image select in carousel
    this.removeIntersectionObserver = addIntersectionObserver(this.boundaryElement, this.adjustFloatingPosition)
  }

  disconnect () {
    this.removeResizeHandler()
    this.removeScrollHandler()
    this.removeIntersectionObserver()
  }

  get boundaryElement () {
    return this.floatingAddToCartBoundaryTarget
  }

  handleResize () {
    this.screenSize = new ScreenSize(window)
    this.viewportHeight = window.innerHeight

    this.adjustFloatingPosition()
  }

  adjustFloatingPosition () {
    if (this.screenSize > ScreenSize.md) { return }

    const bottomBoundary = this.boundaryElement.getBoundingClientRect().bottom
    const elementHeight = this.floatingAddToCartTarget.clientHeight + 1

    const bottomOffset = Math.min(0, Math.max(-elementHeight, this.viewportHeight - (bottomBoundary + elementHeight)))
    this.floatingAddToCartTarget.style.bottom = `${bottomOffset}px`
  }

  pushDataLayerEcommerce () {
    const attributes = JSON.parse(attr('data-item-ecommerce', this.element))
    const viewItemAttributes = JSON.parse(attr('data-item-ecommerce-standard', this.element))
    const list = attr('data-list', this.element)

    dataLayer.push({ ecommerce: null })
    dataLayer.push({
      event: 'ce_detail',
      ecommerce: {
        detail: {
          actionField: { list },
          products: [attributes]
        }
      }
    })
    dataLayer.push(viewItemAttributes)
  }
}
