import { curry } from 'ramda'

/**
 * @param className
 * @param classList
 * @param value
 * @return {*}
 */
export default curry((className, classList, value) => {
  if (value) {
    classList.add(className)
  } else {
    classList.remove(className)
  }
})
