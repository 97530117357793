import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['countryLink', 'selectedCountry', 'locationButton']

  connect () {
    this.countryLinks = this.countryLinkTargets
    this.selectedCountry = this.selectedCountryTarget
    this.locationButton = this.locationButtonTarget

    this.countryLinks.forEach((link) => {
      if (this.selectedCountry.dataset.locale === link.dataset.locale) {
        this.locationButton.setAttribute('data-href', link.href)
      }
      link.addEventListener('click', this.selectCountry.bind(this))
    })

    this.locationButton.addEventListener(
      'click',
      this.redirectToCountry.bind(this)
    )
  }

  selectCountry (event) {
    event.preventDefault()

    this.countryLinks.forEach((link) => {
      Array.from(link.children).forEach((child) => {
        child.classList.remove('fw-semibold')
      })
    })

    const clickedLink = event.currentTarget
    const clickedChild = clickedLink.children[1]
    clickedChild.classList.add('fw-semibold')

    const newLocaleText = clickedLink.textContent.trim()
    const newHref = clickedLink.getAttribute('href')

    this.selectedCountry.textContent = newLocaleText
    this.locationButton.setAttribute('data-href', newHref)
  }

  redirectToCountry () {
    const redirectUrl = this.locationButton.getAttribute('data-href')
    if (redirectUrl) {
      window.location.href = redirectUrl
    }
  }
}
