import { parseCookie, split, throwError } from 'utils'

class PlainTextCookies {
  /**
   * @see proxify
   * @param cookies {Object}
   * @return {Proxy}
   */
  static build (cookies) {
    const instance = new this(cookies)
    return this.proxify(instance)
  }

  /**
   * Utilizes proxy to allow hash-style getters with square brackets to any cookie property
   *
   * @param instance {Class}
   * @return {Proxy}
   */
  static proxify (instance) {
    return new Proxy(instance, {
      get: (target, name) => name in target ? target[name] : target.get(name),
      set: (target, name, value) => target.set(name, value)
    })
  }

  constructor (cookies) {
    this.cookies = cookies
  }

  get (name) {
    return this.cookies[name]
  }

  set (name, value) {
    throwError('setting cookies is not supported')
  }
}

class SignedCookies extends PlainTextCookies {
  get (name) {
    const [value] = split('--', super.get(name))
    return this.decode(value)
  }

  decode (base64Value) {
    const decoded = this.parse(base64Value)
    return this.parse(decoded._rails.message)
  }

  parse (base64Value) {
    return JSON.parse(window.atob(base64Value))
  }
}

class RotatingCookies extends PlainTextCookies {
  constructor (cookies) {
    super(cookies)
    this.signed = SignedCookies.build(cookies)
  }

  get encrypted () {
    throwError('encrypted cookie is not supported yet, and would be a security flaw to support them!')
  }
}

/**
 * Rails-like implementation of a cookie jar, which allows you to operate with cookies same way:
 * @example
 *   // using classic getter
 *   cookies.get('uuid')
 *   // using rails-style notations
 *   cookies['uuid']
 *   // same with signed cookies
 *   cookies.signed['uuid']
 */
export default RotatingCookies.build(parseCookie(document.cookie))
