import { Controller } from 'stimulus'
import { Carousel } from 'bootstrap'
import SwipeListener from 'swipe-listener'

export default class extends Controller {
  static targets = ['image', 'images']

  connect () {
    this.carousel = new Carousel(this.element, { touch: false, interval: 0, ride: false })
    if (this.isMobile()) {
      this.deactivateZoom()
      this.swipeListener = SwipeListener(this.imageTarget)
      this.imageTarget.addEventListener('swipe', (event) => this.handleSwipeEvent(event))
    }
  }

  deactivateZoom () {
    this.imagesTargets.forEach(function (image) {
      image.dataset.bsToggle = null
    })
  }

  handleSwipeEvent (event) {
    if (!this.isMobile()) {
      return
    }

    const directions = event.detail.directions

    if (directions.left) {
      this.carousel.next()
    }

    if (directions.right) {
      this.carousel.prev()
    }
  }

  isMobile () {
    return Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf('Mobi') > -1
  }
}
