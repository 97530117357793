/**
 * A neat wrapper around promise allowing to continue code execution after CSS transition happened,
 * which combination of "await" it gives quite natural and straightforward code.
 *
 * @example
 *   // how it usually looks
 *   slide (el) {
 *     el.classList.add('slide-in')
 *     setTimeout(() => {
 *       el.classList.remove('slide-in')
 *     }, 300)
 *   }
 *
 *   // using this function it becomes much more readable
 *   async slide (el) {
 *     el.classList.add('slide-in')
 *     await transition(300)
 *     el.classList.remove('slide-in')
 *   }
 *
 * @param {Number} value milliseconds of transition to wait
 * @return {Promise} promise that would be resolved when duration set by value is ended
 */
export default (value) => new Promise((resolve) => setTimeout(resolve, value))
