/**
 * A thin wrapper around typical addEventListener functionality
 * @param {Object} target - an obj on which to apply listener
 * @param {String} name - event name to listen
 * @param {Function} callback - callback that would be invoked
 * @param {Boolean} callInitial - when "true" it would call provided callback during invocation
 * @return {function(): *} a function that would remove your listener, so that you don't even need to
 *  save our callback somewhere in order to remove listener, save only this returned function.
 */
export default (target, name, callback, callInitial = false) => {
  target.addEventListener(name, callback)
  if (callInitial) { callback() }
  return () => target.removeEventListener(name, callback)
}
