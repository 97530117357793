import { Controller } from 'stimulus'
import { attr } from 'utils/dom'

export default class extends Controller {
  static targets = ['dropdownAmount', 'itemsAmount', 'form', 'favoriteLink']

  amountChanged (event) {
    const amount = parseInt(event.target.textContent)

    this.setDropdownAmount(amount)
    this.setItemsAmount(amount)
    this.submitForm()
  }

  removeFromCart (event) {
    const url = attr('data-url', this.favoriteLinkTarget)
    const isFavorited = event.detail[0].state

    if (isFavorited) {
      Rails.ajax({
        url,
        type: 'delete',
        success: () => {}
      })
    }
  }

  setDropdownAmount (amount) {
    this.dropdownAmountTarget.textContent = amount
  }

  setItemsAmount (amount) {
    this.itemsAmountTarget.value = amount
  }

  submitForm () {
    Rails.fire(this.formTarget, 'submit')
  }
}
