import { Controller } from 'stimulus'
import { attr, reflow } from 'utils/dom'
import { init, tail, forEach, transition } from 'utils'

/**
 * Sliding navigation controller, build upon HTML5 <template> tags.
 * To make it work, you simply need to add templates with ids, and put links with `data-template-id` attributes.
 *
 * @example
 *   %div(data-controller="slider" data-slider-root-template-id-value="root-slide")
 *     %template(id="root-slide")
 *       %a(data-action="slider#slideForward" data-template-id="about-us") About Us
 *       %a(href="google.com") Just a regular link
 *     %template(id="about-us")
 *       %h3 About Us
 *       %p Here we render some content and blah blah
 *       %a(data-action="slider#slideBackward" data-template-id="root-slide") Back
 *
 * `content` target is optional, and is allowing you to specify a place where content should be rendered and where
 * templates are located (by default controller would use mount element)
 *
 * @example
 *  %div(data-controller="slider" data-slider-root-template-id-value="root-slide")
 *    %div
 *      %div(data-slider-target="content")
 *        %template(id="root-slide")
 *          Blah blah
 */
export default class extends Controller {
  static targets = ['content', 'slide']
  static values = {
    rootTemplateId: String
  }

  connect () {
    this.transitionDuration = 300 // should be kept in sync with styles
    this.transitioning = false
    this.renderRootTemplate()
  }

  /**
   * [ACTION] add slide to right and perform "forward" transition
   * @param {Event} e
   */
  slideForward (e) {
    e.preventDefault()
    if (this.transitioning) { return }

    const id = attr('data-template-id', e.currentTarget)
    const template = this.getSlideTemplate(id)
    this.appendTemplate(template)
  }

  /**
   * [ACTION] add slide to left and perform "back" transition
   * @param {Event} e
   */
  slideBackward (e) {
    e.preventDefault()
    if (this.transitioning) { return }

    const id = attr('data-template-id', e.currentTarget)
    const template = this.getSlideTemplate(id)
    this.prependTemplate(template)
  }

  /**
   * [ACTION] reset state to first slide skipping (!) any animations
   */
  reset () {
    const currentSlideId = attr(`data-${this.identifier}-slide-id`, this.slideTarget)
    if (currentSlideId === this.rootTemplateIdValue) { return }
    this.renderRootTemplate()
  }

  renderRootTemplate () {
    const rootTemplate = this.getSlideTemplate(this.rootTemplateIdValue)
    this.appendTemplate(rootTemplate, true)
  }

  /**
   * Returns an element where all the templates stored and where slides would be displayed,
   * can be specified by either `data-target="content"` or defaults to controller mount element
   * @return {Element}
   */
  get content () {
    return this.hasContentTarget ? this.contentTarget : this.element
  }

  getSlideTemplate (id) {
    const template = this.content.querySelector(`template#${id}`)
    if (!template) { throw new Error('no template found') }

    const content = template.content.firstElementChild.cloneNode(true)
    content.setAttribute(`data-${this.identifier}-target`, 'slide')
    content.setAttribute(`data-${this.identifier}-slide-id`, id)

    return content
  }

  appendTemplate (template, instant = false) {
    if (instant) {
      return this.renderSlideInstantly(template)
    }

    template.classList.add('navigation-slider-slide-slide-in')

    this.withinTransition(async () => {
      this.content.appendChild(template)
      await transition(this.transitionDuration)
      forEach((el) => el.remove(), init(this.slideTargets))
    })
  }

  prependTemplate (template, instant = false) {
    if (instant) {
      return this.renderSlideInstantly(template)
    }

    // apply slide-out animation and make it work with reflow
    const previous = this.slideTarget
    previous.classList.remove('navigation-slider-slide-slide-in')
    reflow(previous)
    previous.classList.add('navigation-slider-slide-slide-out')

    this.withinTransition(async () => {
      this.content.insertBefore(template, previous)
      await transition(this.transitionDuration)
      forEach((el) => el.remove(), tail(this.slideTargets))
    })
  }

  renderSlideInstantly (template) {
    forEach((el) => el.remove(), this.slideTargets)
    this.content.appendChild(template)
  }

  withinTransition (callback) {
    this.transitioning = true
    this.content.classList.add('overflow-hidden')
    callback()
    this.content.classList.remove('overflow-hidden')
    this.transitioning = false
  }
}
